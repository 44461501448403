import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ServiceAdvisorFormStateService } from '../../service/service-advisor-formStateService';
import { UserService } from '../../../../Service/User/user.service';

@Component({
  selector: 'app-service-advisor-resume',
  templateUrl: './service-advisor-resume.component.html',
  styleUrls: ['./service-advisor-resume.component.scss'],
})
export class ServiceAdvisorResumeComponent implements OnInit {
  countryId: any;
  form = new FormGroup({});
  model: any = {};
  isNewFileUploaded = false;

  fields: FormlyFieldConfig[] = [];
  @Output() nextSection = new EventEmitter<void>();
  @Output() formValidity = new EventEmitter<boolean>();
  @Input() receivedObject: any;
  @Input() editMode!: boolean;

  constructor(
    private api: UserService,
    private formStateService: ServiceAdvisorFormStateService,
    private fileService: ServiceAdvisorFormStateService,
    private cdr: ChangeDetectorRef  // Inject ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    console.log('Initializing ServiceAdvisorResumeComponent');
    if (this.receivedObject) {
      this.populateFormWithServiceProviderDetails(this.receivedObject);
    }
    this.initializeFields();
  }

  ngOnDestroy() {
    this.saveFormData();
  }

  resetForm() {
    this.form.reset();
    this.model = {};
  }

  initializeFields() {
    const fileSourceId = 3;
    this.fields = [
      {
        key: 'file',
        type: 'file-upload',
        templateOptions: {
          label: 'File Upload',
          placeholder: 'Upload a file',
          required: true,
          accept: '.pdf,.jpg,.png',
          fileSourceId,
        },
      },
    ];
  }

  /**
   * Patches the form and file service with file data
   * from the backend or manual upload
   */
  patchFormWithFile(fileName: string, fileUrl: string, fileId: number) {
    // Patch form with file data
    this.form.patchValue({ file: fileUrl });

    // Update formStateService with file information
    this.fileService.setFormData('resumeFile', {
      fileName: fileName,
      fileUrl: fileUrl,
      fileId: fileId,
    });

    // Store uploaded file ID in fileService
    this.fileService.setUploadedFileId('resumeFile', fileId);

    // Trigger change detection
    this.cdr.detectChanges();
  }

  /**
   * Populates form with backend data
   * @param res
   */
  populateFormWithServiceProviderDetails(res: any) {
    const resumeDocument = res.serviceAdvisorDocuments?.find(
      (doc: any) => doc.documentTypeId === 12
    );

    const uploadedFileId = this.fileService.getUploadedFileId('resumeFile');

    if (!uploadedFileId && resumeDocument) {
      console.log('Patching backend resume data.');

      const { fileName, filePath: fileUrl, fileId } = resumeDocument;

      // Patch the form with file from backend using the new method
      this.patchFormWithFile(fileName, fileUrl, fileId);
    } else {
      console.log('Skipping backend patch, uploaded file ID exists:', uploadedFileId);
    }
  }

  saveFormData() {
    const formData: any = { ...this.form.value };

    const uploadedFileId = this.fileService.getUploadedFileId('resumeFile');
    console.log('Uploaded file id ::', uploadedFileId);

    if (uploadedFileId) {
      const documentTypeId = 12;
      const uploadDocs = {
        documents: [
          {
            documentTypeId,
            fileId: uploadedFileId,
          },
        ],
      };

      this.formStateService.setFormData('upload-documents', formData);
      this.formStateService.setServiceAdvisorResume(uploadDocs);
      console.log('Form data and uploaded document saved successfully');
    } else {
      console.error('No uploaded file ID found. Please ensure the file is uploaded.');
    }
  }

  /**
   * This method is called if backend data exists in formStateService
   */
  loadFormData() {
    const savedData = this.formStateService.getFormData('upload-documents');
    if (savedData) {
      const { fileName, fileUrl, file } = savedData;
      console.log('Loaded saved data:', savedData);

      // Use the new centralized method to patch form and state
      this.patchFormWithFile(fileName, fileUrl, file);
    }
  }

  onSubmit() {
    this.saveFormData();
    this.nextSection.emit();
    const data = this.formStateService.getServiceAdvisorCombinedData();
    console.log(data);
  }
}
