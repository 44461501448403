import { ChangeDetectionStrategy, Component, Inject, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../Service/User/user.service';
import { FormStateService } from '../../service-provider/add-sp-new-component/form-state.service';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { ToastService } from '../../../Service/ToastService/toast.service';

interface EventItem {
  status?: string;
  date?: string;
  icon?: string;
  color?: string;
  image?: string;
}

@Component({
  selector: 'app-service-advisor-details',
  templateUrl: './service-advisor-details.component.html',
  styleUrl: './service-advisor-details.component.scss',
})
export class ServiceAdvisorDetailsComponent {
  user: any = {};
  userId!: any;
  visibleViewer = false;
  showDocumentViewer = false;
  selectedDocumentUrl: string | undefined;
  userDocuments: any[] = [];
  currentSection = 0;
  rejectModal: boolean = false;
  visibleApproveModal: boolean = false;
  currentTab: string = 'documents';
  activeTab: string = 'documents';
  documents: any[] = [];
  resumes: any[] = [];
  showImageViewer: boolean = false;
  selectedImageUrl: string | undefined;
  selectedImage: any;
  pdfSrc: string | ArrayBuffer | null = null;
  page: number = 1;
  totalPages: number = 0;
  serviceAdvisorId: any;
  serviceadvisor: any;
  showModal = false;
  idToEdit!: any;
  events!: EventItem[];
  showMoreCards = false;
  selectedDocument: any;
  toastMessage = '';
  toastType: 'success' | 'error' = 'success';
  showToast = false;
  reviewModal: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: UserService,
    private formStateService: FormStateService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient,
    private toastService: ToastService
  ) {

  }
  get shouldHideButtons(): boolean {
    return this.user.status === 'Rejected' || this.user.status === 'Approved' || this.user.status === 'Active';
  }

  get showRejectButton(): boolean {
    return this.user.status === 'Submitted';
  }

  get showEditButton(): boolean {
    return this.user.status === 'Submitted' || this.user.status === 'Review';
  }

  get showMarkAsReviewButton(): boolean {
    return this.user.status === 'Submitted' || this.user.status === 'Review';
  }

  get showApproveButton(): boolean {
    return this.user.status === 'Submitted' || this.user.status === 'Review';
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.userId = params.get('serviceAdvisorId');
      this.formStateService.setUserId(this.userId);
      this.getUsersDetails(); // Make only one API call
      this.getAdvisorHistory();
    });
    
  }
  getAdvisorHistory() {
    this.api.getAdvisorStatusHistory(this.userId).subscribe((data: any) => {
      this.events = data;
    });
  }

  toggleMoreCards() {
    this.showMoreCards = !this.showMoreCards;
  }
  calculateAge(dob: string): number {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }

  getUsersDetails() {
    if (this.userId) {
      this.api.getServiceAdvisorById(this.userId).subscribe(
        (res: any) => {
          this.user = res;
          this.user.age = this.calculateAge(this.user.dob);
          this.userDocuments = res.serviceAdvisorDocuments || [];
          this.resumes = this.userDocuments.filter(
            (doc: any) => doc.documentTypeId === 12
          );
          this.documents = this.userDocuments.filter(
            (doc: any) => doc.documentTypeId !== 12
          );
          console.log(this.user);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  navigateTo() {
    this.router.navigate(['/service-advisors']);
  }

  clickRejectModal() {
    this.rejectModal = true;
  }

  closeRejectModal() {
    this.rejectModal = false;
  }

  openApproveModal() {
    this.visibleApproveModal = true;
  }

  closeApproveModal() {
    this.visibleApproveModal = false;
  }

  approve() {
    this.getUsersDetails();
  }

  reject() {
    this.getUsersDetails();
  }

  downloadFile(document: any): void {
    if (document && document.filePath) {
      const fileUrl = `${this.api.api}/${document.filePath}`;

      this.http.get(fileUrl, { responseType: 'blob' }).subscribe(
        (blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = this.renderer.createElement('a');
          this.renderer.setAttribute(a, 'href', url);
          this.renderer.setAttribute(a, 'download', document.fileName);
          this.renderer.setStyle(a, 'display', 'none');
          this.renderer.appendChild(this.document.body, a);

          a.click();

          window.URL.revokeObjectURL(url);
          this.renderer.removeChild(this.document.body, a);

          // Trigger success toast
          this.toastService.showToast('Download Successful', 'success');
        },
        (error) => {
          console.error('Error downloading file:', error);
          this.toastService.showToast('Unable to download the file.', 'error'); // Trigger error toast
        }
      );
    } else {
      console.error('Invalid document data for download:', document);
      this.toastService.showToast(
        'Invalid document data for download.',
        'error'
      );
    }
  }

  getDocumentDescription(documentTypeId: number): string {
    switch (documentTypeId) {
      case 7:
        return 'NID/Labour card/ Aadhar';
      case 13:
        return 'Share your qualification certificates';
      case 14:
        return 'Share your experience certificates';
      case 15:
        return 'Share any other auto certificates';
      default:
        return '';
    }
  }
  removeDocument(document: any) {
    console.log('Removing document:', document);
  }
  trackByDocumentId(index: number, document: any): number {
    return document.documentId;
  }
  openImageViewer(document: any): void {
    if (document && document.filePath) {
      this.selectedImageUrl = `${this.api.api}/${document.filePath}`;
      this.selectedImage = document;
      this.showImageViewer = true;
    } else {
      console.error('Invalid document data for image viewing:', document);
    }
  }

  closeImageViewer(): void {
    this.showImageViewer = false;
    this.selectedImageUrl = undefined;
    this.selectedImage = undefined;
  }

  isImage(fileName: string): boolean {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
    const fileExtension = fileName.split('.').pop()?.toLowerCase();
    return imageExtensions.includes(fileExtension || '');
  }

  getDocumentUrl(document: any): string {
    if (!document || !document.filePath) {
      return '';
    }
    return `${this.api.api}/${document.filePath}`;
  }

  pdfLoaded(pdf: any): void {
    this.totalPages = pdf.numPages;
  }

  previousPage(): void {
    if (this.page > 1) {
      this.page--;
    }
  }

  nextPage(): void {
    if (this.page < this.totalPages) {
      this.page++;
    }
  }
  openEditModal(id: any) {
    this.idToEdit = id;
    this.showModal = true;
    console.log(this.idToEdit);
  }

  showModalW() {
    this.showModal = false;
    this.getUsersDetails();
  }
  openDocumentViewer(document: any): void {
    if (document && document.filePath) {
      this.selectedDocumentUrl = `${this.api.api}/${document.filePath}`;
      this.selectedDocument = document;
      this.showDocumentViewer = true;
    } else {
      console.error('Invalid document data for viewing:', document);
    }
  }

  closeDocumentViewer(): void {
    this.showDocumentViewer = false;
    this.selectedDocumentUrl = undefined;
    this.selectedDocument = undefined;
  }

  isPdfFile(fileName: string): boolean {
    return fileName.toLowerCase().endsWith('.pdf');
  }

  openReviewModal() {
    this.reviewModal = true;
  }
  closeReviewModal() {
    this.reviewModal = false;
  }

  markForReview() {
    this.reviewModal = false;
  }
}
