<div class="form-container">
  <div class="header-container">
    <div style="margin-bottom: 43px" class="mt-5 font-semibold text-xl">
      Upload Resume
    </div>
  </div>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
    <button type="submit" class="custom-upload-button">
      Upload and Next
      <span class="arrow">➔</span>
    </button>
  </form>
</div>
<!-- [disabled]="form.invalid" -->
