// 1st child component
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { UserService } from '../../../../Service/User/user.service';
import { ServiceProviderService } from '../../../../Service/Service-providers/service-provider.service';
import { ServiceAdvisorFormStateService } from '../../service/service-advisor-formStateService';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-service-advisor-details',
  templateUrl: './add-service-advisor-details.component.html',
  styleUrl: './add-service-advisor-details.component.scss',
})
export class AddServiceAdvisorDetailsComponent implements OnInit, OnDestroy {
  serviceAdvisorId: any;
  form = new FormGroup({});
  // model: any = {};
  model: any = {
    serviceAdvisorId: '',
    name: '',
    email: '',
    phone: '',
    dob: '',
    professionalExperience: '',
    qualification: '',
    bio: '',
    stateId: '',
    countryId: '',
    city: '',
    zip: '',
    address: '',
  };
  fields: FormlyFieldConfig[] = [];
  countryId: any;
  countryName!: string;
  @Output() nextSection = new EventEmitter<void>();
  @Output() formValidity = new EventEmitter<boolean>();
  private subscription: Subscription | undefined;
  @Input() receivedObject: any;
  @Input() editMode!: boolean;
  constructor(
    private api: ServiceProviderService,
    private userService: UserService,
    private formStateService: ServiceAdvisorFormStateService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.loadFormData();
    const tokenDetailsString = localStorage.getItem('tokenDetails');
    if (tokenDetailsString) {
      const tokenDetails = JSON.parse(tokenDetailsString);
      this.countryId = tokenDetails.country?.countryId;
      const storedCountryName = tokenDetails.country?.name;
      if (this.countryId) {
        this.model.countryId = this.countryId;
        this.form.patchValue({ countryId: this.countryId });
        this.initializeField();
      }
    }
    if (this.receivedObject) {
      this.populateFormWithServiceProviderDetails(this.receivedObject);
    }

    // Listen for changes and save to the form state service
    this.form.valueChanges.subscribe((formData) => {
      this.saveFormData();
    });

    // Initialize form fields
    this.initializeField();
  }


  resetForm() {
    this.form.reset();
    this.model = {};
  }

  initializeField() {
    this.fields = [
      {
        template: '<div class="text-base font-semibold mb-4">Basics</div>',
      },
      {
        fieldGroupClassName: 'field grid p-fluid',
        fieldGroup: [
          {
            className: 'field col-5',
            key: 'name',
            type: 'input',
            templateOptions: {
              label: 'Name of the Service advisor',
              placeholder: 'Enter name',
              required: true,
              hideRequiredMarker: true,
            },
            validators: {
              validation: [Validators.required, Validators.minLength(3)],
            },
            validation: {
              messages: {
                required: 'Name is required',
                minlength: 'Name should be at least 3 characters long',
              },
            },
          },
          {
            className: 'field col-5',
            key: 'email',
            type: 'input',
            templateOptions: {
              label: 'Email',
              placeholder: 'Enter email address',
              required: true,
              hideRequiredMarker: true,
            },
            validators: {
              validation: [Validators.required, Validators.email],
            },
            validation: {
              messages: {
                required: 'Email address is required',
              },
            },
          },
          {
            className: 'field col-5',
            key: 'phone',
            type: 'input',
            templateOptions: {
              label: 'Phone number',
              placeholder: '+91 1234567890',
              required: true,
              hideRequiredMarker: true,
            },
            validators: {
              validation: [Validators.required, Validators.pattern(/^\d{10}$/)],
            },
            validation: {
              messages: {
                required: 'Phone number is required',
                pattern: 'Phone number must be exactly 10 digits',
              },
            },
          },
          {
            className: 'field col-5',
            key: 'dob',
            type: 'custom-min-dt',
            templateOptions: {
              label: 'Date of birth',
              placeholder: 'mm/dd/yyyy',
              required: true,
              hideRequiredMarker: true,
            },
            validation: {
              messages: {
                required: 'Date of birth is required',
              },
            },
          },
          {
            className: 'field col-5',
            key: 'professionalExperience',
            type: 'textarea',
            templateOptions: {
              label: 'Professional Exp.',
              placeholder: 'Mention your work experience here',
              required: true,
              hideRequiredMarker: true,
            },
            validation: {
              messages: {
                required: 'Professional experience is required',
              },
            },
          },
          {
            className: 'field col-5',
            key: 'qualification',
            type: 'textarea',
            templateOptions: {
              label: 'Qualification',
              placeholder: 'Mention your highest achieved qualification',
              required: true,
              hideRequiredMarker: true,
            },
            validation: {
              messages: {
                required: 'Please mention your qualification',
              },
            },
          },
        ],
      },

      {
        fieldGroupClassName: 'field grid p-fluid',
        fieldGroup: [
          {
            className: 'field col-10',
            key: 'bio',
            type: 'textarea',
            templateOptions: {
              label: 'Bio',
              placeholder: 'Enter a description',
              required: true,
              hideRequiredMarker: true,
            },
            validators: {
              validation: [Validators.required, Validators.minLength(10)],
            },
            validation: {
              messages: {
                required: 'Please mention something in your bio',
                minlength: 'Name should be at least 10 characters long',
              },
            },
          },
        ],
      },

      {
        template:
          '<div class="text-base font-semibold mb-4 mt-5">Address</div>',
      },
      {
        fieldGroupClassName: 'field grid p-fluid',
        fieldGroup: [
          {
            className: 'field col-4',
            key: 'countryId',
            type: 'select',
            templateOptions: {
              disabled: true,
              options: this.api.getCountry(''),
              valueProp: 'countryId',
              labelProp: 'name',
              label: 'Country',
              hideRequiredMarker: true,
            },
            hooks: {
              onInit: (field) => {
                if (this.countryId) {
                  field.formControl?.setValue(this.countryId);
                  field.templateOptions!.disabled = true;
                }
              },
            },
          },
          {
            className: 'field col-3',
            key: 'stateId',
            type: 'select',
            templateOptions: {
              placeholder: 'Select State',
              options: this.api.getStates(this.countryId),
              valueProp: 'stateId',
              labelProp: 'name',
              label: 'State',
              required: true,
              hideRequiredMarker: true,
            },
          },
          {
            className: 'field col-3',
            key: 'city',
            type: 'input',
            templateOptions: {
              label: 'City',
              placeholder: 'Select city',
              required: true,
              hideRequiredMarker: true,
            },
          },
          {
            className: 'field col-5 mt-3',
            key: 'address',
            type: 'input',
            templateOptions: {
              label: 'Address',
              placeholder: 'Enter address',
              required: true,
              hideRequiredMarker: true,
            },
          },
          {
            className: 'field col-5 mt-3',
            key: 'zip',
            type: 'input',
            templateOptions: {
              label: 'Pincode',
              placeholder: 'Enter pincode',
              required: true,
              hideRequiredMarker: true,
            },
            validators: {
              validation: [Validators.required, Validators.pattern(/^\d{6}$/)],
            },
            validation: {
              messages: {
                required: 'Pincode is required',
                pattern: 'Pincode must be exactly 6 digits',
              },
            },
          },
        ],
      },
    ];
  }
  ngOnDestroy() {
    this.saveFormData();
  }

  saveFormData() {
    const formData = { ...this.form.value };
    this.formStateService.setFormData('service-advisor-details', formData);
    console.log(formData);
  }

  loadFormData() {
    const savedData = this.formStateService.getFormData(
      'service-advisor-details'
    );
    if (savedData) {
      console.log('Saved Data:', savedData);
      this.model = savedData;
      this.form.patchValue(savedData);
    }
  }

  onSubmit() {
    if (this.form.valid) {
      const transformedData = this.transformFormData(this.form.value);
      this.formStateService.setServiceAdvisorDetails(transformedData);
      console.log(transformedData);
      this.nextSection.emit();
    }
  }

  transformFormData(formData: any): any {
    const transformedData: any = {
      name: formData.name,
      phoneNumber: formData.phone,
      emailAddress: formData.email,
      bio: formData.bio,
      address: formData.address,
      stateId: formData.stateId,
      countryId: this.countryId,
      city: formData.city,
      zip: formData.zip,
      dob: new Date(formData.dob).toISOString(),
      professionalExperience: formData.professionalExperience,
      qualification: formData.qualification,
    };
  
    // will be included if the serviceAdvisorId exists
    if (this.serviceAdvisorId) {
      transformedData.serviceAdvisorId = this.serviceAdvisorId;
    }
  
    return transformedData;
  }
  populateFormWithServiceProviderDetails(res: any) {
    this.serviceAdvisorId=res.serviceAdvisorId;
    console.log('Advisor id',this.serviceAdvisorId)
    const formattedDob = res.dob
      ? this.formatDateToMMDDYYYY(new Date(res.dob))
      : '';
    this.model.name = res.name;
    this.model.phone = res.phoneNumber;
    this.model.email = res.emailAddress;
    this.model.bio = res.bio;
    this.model.address = res.address;
    this.model.stateId = res.state?.stateId;
    this.model.countryId = res.country?.countryId;
    this.model.zip = res.zip;
    this.model.qualification = res.qualification;
    this.model.professionalExperience = res.professionalExperience;
    this.model.dob = formattedDob;
    this.model.city = res.city;

    this.form.patchValue(this.model);
    this.formStateService.setFormData('service-advisor-details', this.model);
    this.cdr.detectChanges();
  }

  formatDateToMMDDYYYY(date: Date): string {
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }
}
