<!-- Parent component's html -->
<div class="overlay">
  <div class="modal" style="width: 100%; height: 100%; overflow-y: auto">
    <div class="modal-content">
      <div class="flex justify-content-between flex-wrap" style="
          padding-top: 10px;
          padding-right: 2px;
          padding-left: 82px;
          border-bottom: 1px solid #f2f4f7;
        ">
        <div class="img">
          <div class="flex justify-content-start flex-wrap">
            <div class="flex align-items-center justify-content-center mr-1">
              <img src="assets/image/Logomark.png" alt="Logo" class="svg-black" />
            </div>
            <div class="flex align-items-center justify-content-center">
              <p style="color: #1d2939; font-weight: 700; font-size: 18px">
                Auto E Care
              </p>
            </div>
          </div>
        </div>
        <div class="flex align-items-center justify-content-center">
          <p style="font-weight: 700; color: #333; font-size: 24px">
            {{
            editMode
            ? "Update Service Advisor Form"
            : "Add Service Advisor Form"
            }}
          </p>
        </div>
        <div class="align-items-center justify-content-center m-3">
          <i class="pi pi-times" style="cursor: pointer; font-size: 1em" (click)="close()"></i>
        </div>
      </div>

      <!-- Combined Sidebar and Content Section -->
      <div class="flex" style="height: calc(100vh - 70px); overflow-y: auto">
        <div class="sidebar" style="margin-top: 82px">
          <ul>
            <li>
              <a (click)="setActiveSection('service-advisor-details')" [ngClass]="{
                  active: activeSection === 'service-advisor-details'
                }">
                <i class="pi pi-user"></i>Service Advisor Details
              </a>
            </li>
            <li>
              <a (click)="setActiveSection('resume')" [ngClass]="{ active: activeSection === 'resume' }">
                <i class="pi pi-cloud-upload"></i>Resume
              </a>
            </li>
            <li>
              <a (click)="setActiveSection('service-advisor-certificates')" [ngClass]="{
                  active: activeSection === 'service-advisor-certificates'
                }">
                <i class="pi pi-list"></i> Upload Certificates
              </a>
            </li>
          </ul>
        </div>

        <!-- Conditional Content Rendering -->
        <div class="content" style="flex-grow: 1">
          <app-add-service-advisor-details *ngIf="activeSection === 'service-advisor-details'"
            (nextSection)="setActiveSection('resume')"
            [editMode]="editMode"></app-add-service-advisor-details>

          <app-service-advisor-resume *ngIf="activeSection === 'resume'"
            [editMode]="editMode"
            (nextSection)="setActiveSection('service-advisor-certificates')"></app-service-advisor-resume>

          <app-service-advisor-certificates *ngIf="activeSection === 'service-advisor-certificates'" (close)="close()"
            (updateList)="onUpdateUserList()"></app-service-advisor-certificates>
        </div>
      </div>
    </div>
  </div>
</div>