<!-- Navigation and Header -->
<div class="top-container" #target>
  <div class="flex justify-content-between flex-wrap">
    <div class="flex align-items-center justify-content-center m-3">
      <i
        class="pi pi-arrow-left"
        style="font-size: 1rem; cursor: pointer"
        (click)="navigateTo()"
      ></i>
    </div>
    <div class="flex align-items-center justify-content-center m-2">
      <span class="m-1" *ngIf="showRejectButton">
        <button
          class="btn-1 btn-warning-1 outlined-1 small raised"
          (click)="clickRejectModal()"
        >
          Reject
        </button>
      </span>
      <span class="m-1" *ngIf="showEditButton">
        <button
          class="btn-1 btn-warning-edit outlined-2 small raised"
          (click)="openEditModal(user?.serviceAdvisorId)"
        >
          Edit
        </button>
      </span>
      <span class="m-1" *ngIf="showMarkAsReviewButton">
        <button
          (click)="openReviewModal()"
          class="btn-1 btn-warning-1 outlined-2 small raised"
        >
          Mark as Review
        </button>
      </span>
      <span class="m-1" *ngIf="showApproveButton">
        <button
          class="btn-1 btn-secondary-warning small raised"
          (click)="openApproveModal()"
        >
          Approve
        </button>
      </span>
    </div>
  </div>

  <!-- Card Section -->
  <div class="card-top">
    <div class="card">
      <div class="card-content">
        <div class="avatar">
          <span *ngIf="user && user.name">
            {{ user.name.charAt(0) }}
            <ng-container *ngIf="user.name.split(' ')[1]">
              {{ user.name.split(" ")[1].charAt(0) }}
            </ng-container>
          </span>
        </div>
        <div class="info">
          <div class="name-status">
            <div class="name">{{ user.name }}</div>
          </div>
          <div class="bio">About {{ user.name }}</div>
          <div class="bio-details">{{ user.bio }}</div>
          <div class="details">
            <div class="detail">
              <div class="detail-title">Phone Number</div>
              <div class="detail-content">{{ user.phoneNumber }}</div>
            </div>
            <div class="detail">
              <div class="detail-title">Address</div>
              <div class="detail-content">
                {{ user.address }}, {{ user.city }}, {{ user.state?.name }},
                {{ user.country?.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <span
        class="tag"
        [ngClass]="{
          submitted: user.status === 'Submitted',
          approved: user.status === 'Accepted',
          inactive: user.status === 'Inactive',
          rejected: user.status === 'Rejected'
        }"
        >{{ user.status === "Accepted" ? "Active" : user.status }}</span
      >
    </div>
  </div>

  <!-- Card Section -->
  <div class="card-top" class="card-top" *ngIf="showMoreCards">
    <div class="card">
      <div class="card-content">
        <div class="info">
          <div class="name-status">
            <div class="section">Summary</div>
          </div>
          <div class="details">
            <div class="mr-8 w-6">
              <div
                class="mb-2"
                style="
                  color: #475467;
                  font-size: 14px;
                  font-weight: 500;
                  width: 320px;
                "
              >
                No of Service Seekers onboarded
              </div>
              <div
                style="
                  color: var(--Gray-900, #101828);
                  font-family: Inter;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                "
              >
                0
              </div>
            </div>
            <div>
              <div
                class="mb-2"
                style="color: #475467; font-size: 14px; font-weight: 500"
              >
                No of Service Providers onboarded
              </div>
              <div
                style="
                  color: var(--Gray-900, #101828);
                  font-family: Inter;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                "
              >
                0
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- New Section for Qualification Details, Age, and Professional Experience -->
  <div class="card-top" class="card-top" *ngIf="showMoreCards">
    <div class="card">
      <div class="card-content">
        <div class="info">
          <div class="name-status">
            <div class="section">Service Advisor Details</div>
          </div>
          <div class="details">
            <div class="mr-8 w-13rem">
              <div
                class="mb-2"
                style="color: #475467; font-size: 14px; font-weight: 500"
              >
                Qualification
              </div>
              <div
                style="
                  color: var(--Gray-900, #101828);
                  font-family: Inter;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                "
              >
                {{ user.qualification }}
              </div>
            </div>
            <div class="mr-8 w-13rem">
              <div
                class="mb-2"
                style="color: #475467; font-size: 14px; font-weight: 500"
              >
                Age
              </div>
              <div
                style="
                  color: var(--Gray-900, #101828);
                  font-family: Inter;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                "
              >
                {{ user.age }} years
              </div>
            </div>
            <div class="w-13rem">
              <div
                class="mb-2"
                style="color: #475467; font-size: 14px; font-weight: 500"
              >
                Professional Experience
              </div>
              <div
                style="
                  color: var(--Gray-900, #101828);
                  font-family: Inter;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                "
              >
                {{ user.professionalExperience }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="more-button-container">
    <button class="link-button" (click)="toggleMoreCards()">
      {{ showMoreCards ? "Show Less" : "More" }}
    </button>
  </div>
</div>
<!-- Tab Section -->
<div class="document-tabs">
  <div class="tab-header">
    <div
      class="tab-item"
      [ngClass]="{ active: activeTab === 'documents' }"
      (click)="activeTab = 'documents'"
    >
      Uploaded document
    </div>
    <div
      class="tab-item"
      [ngClass]="{ active: activeTab === 'resume' }"
      (click)="activeTab = 'resume'"
    >
      Resume
    </div>
    <div
      class="tab-item"
      [ngClass]="{ active: activeTab === 'history' }"
      (click)="activeTab = 'history'"
    >
      History
    </div>
  </div>

  <div class="tab-content">
    <!-- Uploaded Documents Tab -->
    <div *ngIf="activeTab === 'documents'" class="document-list-wrapper">
      <ul class="document-list">
        <li *ngFor="let document of documents " class="document-list-item">
          <div class="document-row">
            <div class="document-header">
              <h4>{{ document.documentName }}</h4>
              <p class="document-description">
                {{ getDocumentDescription(document.documentTypeId) }}
              </p>
            </div>
            <div class="document-info-container">
              <div class="document-icon circle1">
                <i
                  *ngIf="isPdfFile(document.fileName)"
                  class="pi pi-file-pdf circle"
                  style="font-size: 12px"
                ></i>
                <i
                  *ngIf="isImage(document.fileName)"
                  class="pi pi-image circle"
                  style="font-size: 12px"
                ></i>
              </div>
              <div class="document-details">
                <span class="document-name">{{ document.fileName }}</span>
              </div>
              <div class="document-actions">
                <button
                  pButton
                  type="button"
                  icon="pi pi-eye"
                  class="p-button-text p-button-sm p-button-rounded"
                  (click)="openDocumentViewer(document)"
                ></button>
                <button
                  pButton
                  type="button"
                  icon="pi pi-download"
                  class="p-button-text p-button-sm p-button-rounded p-button-secondary"
                  (click)="downloadFile(document)"
                ></button>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <!-- PDF Viewer Modal -->
    <p-dialog
      [(visible)]="showDocumentViewer"
      [modal]="true"
      [style]="{ width: '80vw', height: '80vh' }"
      [closable]="true"
      (onHide)="closeDocumentViewer()"
      [maximizable]="true"
    >
      <ng-template pTemplate="header">
        <h4>{{ selectedDocument?.documentName }}</h4>
      </ng-template>
      <ng-template pTemplate="content">
        <pdf-viewer
          *ngIf="isPdfFile(selectedDocument.fileName) && selectedDocumentUrl"
          [src]="selectedDocumentUrl!"
          [render-text]="true"
          [original-size]="false"
          style="display: block; width: 100%; height: 100%"
        >
        </pdf-viewer>
        <img
          *ngIf="isImage(selectedDocument.fileName)"
          [src]="selectedDocumentUrl"
          alt="Document Image"
          style="max-width: 100%; max-height: 70vh"
        />
      </ng-template>
    </p-dialog>

    <!-- Resume Tab -->
    <div *ngIf="activeTab === 'resume'" class="resume-content">
      <div *ngFor="let resume of resumes">
        <div
          *ngIf="isImage(resume.fileName); else pdfViewer"
          class="flex justify-content-center"
        >
          <img
            [src]="getDocumentUrl(resume)"
            alt="Resume Image"
            style="max-width: 100%; max-height: 400px"
          />
          <div class="flex justify-content-end flex-wrap">
            <div>
              <div class="download-button-container">
                <i
                  class="pi pi-download hover"
                  (click)="downloadFile(resume)"
                  style="
                    font-size: 16px;
                    cursor: pointer;
                    color: whitesmoke;
                    font-weight: 600;
                  "
                ></i>
              </div>
            </div>
          </div>
        </div>
        <ng-template #pdfViewer>
          <div class="pdf-container">
            <div class="flex justify-content-end flex-wrap">
              <div>
                <div class="download-button-container">
                  <i
                    class="pi pi-download hover"
                    (click)="downloadFile(resume)"
                    style="
                      font-size: 16px;
                      cursor: pointer;
                      color: whitesmoke;
                      font-weight: 600;
                    "
                  ></i>
                </div>
              </div>
            </div>
            <div>
              <pdf-viewer
                [src]="getDocumentUrl(resume)"
                [show-all]="true"
                (after-load-complete)="pdfLoaded($event)"
                style="display: block; width: 100%; height: 500px"
              >
              </pdf-viewer>
            </div>
          </div>
        </ng-template>
      </div>
    </div>

    <!-- History tab -->
    <div *ngIf="activeTab === 'history'">
      <div>
        <p-timeline [value]="events" class="mt-3">
          <ng-template pTemplate="content" let-event>
            <small class="p-text-secondary">{{ event.createdOn | date }}</small>
          </ng-template>
          <ng-template pTemplate="opposite" let-event>
            {{ event.status }}
          </ng-template>
        </p-timeline>
      </div>
    </div>
  </div>
  
    <div>
      <app-add-service-advisor
        *ngIf="showModal"
        [id]="idToEdit"
        (closeDialog)="showModalW()"
      ></app-add-service-advisor>
    </div>
  </div>

<!-- Modals -->
<p-dialog [(visible)]="reviewModal">
  <ng-template pTemplate="headless">
    <app-review-modal (closeModal)="closeReviewModal()" [resourceType]="'SERVICEADVISOR'"
      [resourceTypeLabel]="'Service advisor'" (onReviewSuccess)="markForReview()"></app-review-modal>
  </ng-template>
</p-dialog>

<p-dialog [(visible)]="rejectModal">
  <ng-template pTemplate="headless">
    <app-reject-modal (closeModal)="closeRejectModal()" [resourceType]="'SERVICEADVISOR'"
      (reject)="reject()"></app-reject-modal>
  </ng-template>
</p-dialog>

<p-dialog [(visible)]="visibleApproveModal">
  <ng-template pTemplate="headless">
    <app-custom-dialog (closeModal)="closeApproveModal()" [title]="'Approve service advisor application?'"
      [message]="'Once you approve,'" [details]="[
          'This service advisor application will be moved to the <span style=\'color: #101828;font-weight: 700;\'>approved</span> queue.'
        ]" [resourceType]="'SERVICEADVISOR'"></app-custom-dialog>
  </ng-template>
</p-dialog>

<p-dialog [(visible)]="showImageViewer" [modal]="true" [style]="{ width: '80vw' }" [closable]="true"
  (onHide)="closeImageViewer()">
  <ng-template pTemplate="header">
    <h4>{{ selectedImage?.fileName }}</h4>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="flex justify-content-center">
      <img [src]="selectedImageUrl" alt="Document Image" style="max-width: 100%; max-height: 70vh" />
    </div>
  </ng-template>
</p-dialog>