// another child component
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ServiceAdvisorFormStateService } from '../../service/service-advisor-formStateService';
import { UserService } from '../../../../Service/User/user.service';
import { ToastService } from '../../../../Service/ToastService/toast.service';
import { FormStateService } from '../../../service-provider/add-sp-new-component/form-state.service';

@Component({
  selector: 'app-service-advisor-certificates',
  templateUrl: './service-advisor-certificates.component.html',
  styleUrls: ['./service-advisor-certificates.component.scss'],
})
export class ServiceAdvisorCertificatesComponent implements OnInit {
  form = new FormGroup({});
  model: any = {
    identityCard: '',
    qualificationCertificates: '',
    experienceCertificates: '',
    otherCertificates: '',
  };
  fields: FormlyFieldConfig[] = [];
  isEditMode: boolean = false;
  fileId: any;
  @Output() nextSection = new EventEmitter<void>();
  @Output() formValidity = new EventEmitter<boolean>();
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() updateList: EventEmitter<void> = new EventEmitter<void>();
  countryId!: any;
  isLoading: boolean = false;
  receivedObject: any;
  checkServiceAdvisorId:any

  constructor(
    private formStateService: ServiceAdvisorFormStateService,
    private api: UserService,
    private toastService: ToastService,
    private cdr: ChangeDetectorRef,
    private getId: FormStateService,

  ) { }

  ngOnInit(): void {
    const tokenDetailsString = localStorage.getItem('tokenDetails');
    if (tokenDetailsString) {
      const tokenDetails = JSON.parse(tokenDetailsString);
      this.countryId = tokenDetails.country?.countryId;
    }
    this.checkServiceAdvisorId = this.getId.getServiceAdvisorId();
    if (this.receivedObject) {
      this.populateFormWithServiceProviderDetails(this.receivedObject);
    }
    this.initializeFields();
  }
  ngOnDestroy() {
    this.saveFormData();
  }
  resetForm() {
    this.form.reset();
    this.model = {};
  }

  initializeFields() {
    this.fields = [
      {
        key: 'identityCard',
        type: 'f-file-upload',
        templateOptions: {
          label: 'Upload Identity card',
          placeholder: 'NID/Labour card/ Aadhar',
          required: true,
          accept: '.pdf,.jpg,.png',
        },
      },
      {
        key: 'qualificationCertificates',
        type: 'f-file-upload',
        templateOptions: {
          label: 'Upload qualification certificates',
          placeholder: 'Share your qualification certificates',
          required: true,
        },
      },
      {
        key: 'experienceCertificates',
        type: 'f-file-upload',
        templateOptions: {
          label: 'Upload experience certificates',
          placeholder: 'Share your experience certificates',
          required: true,
        },
      },
      {
        key: 'otherCertificates',
        type: 'f-file-upload',
        templateOptions: {
          label: 'Any other',
          placeholder: 'Share your experience certificates',
          required: false,
        },
      },
    ];
  }

  saveFormData() {
    const formData = { ...this.form.value };
    const documentMappings: { [key: string]: number } = {
      identityCard: 7,
      qualificationCertificates: 13,
      experienceCertificates: 14,
      otherCertificates: 15,
    };

    const uploadDocs = Object.keys(documentMappings).map((key) => {
      const documentTypeId =
        documentMappings[key as keyof typeof documentMappings];
      const fileId = this.formStateService.getUploadedFileId(key);
      return {
        documentTypeId,
        fileId,
      };
    });

    this.formStateService.setFormData('upload-certificates', formData);
    this.formStateService.setServiceAdvisorCertificates({
      documents: uploadDocs,
    });
  }

  loadFormData() {
    const savedData = this.formStateService.getFormData('upload-certificates');
    if (savedData) {
      this.model = savedData;
      this.form.patchValue(savedData);
    }
  }

  submit() {
    this.close.emit();
  }

  populateFormWithServiceProviderDetails(res: any) {
    // Check if the file was uploaded before using backend data
    if (!this.formStateService.isFileUploaded('identityCard')) {
      const identityCard = res.serviceAdvisorDocuments?.find(
        (doc: any) => doc.documentTypeId === 7
      );
      if (identityCard) {
        this.formStateService.setFormData('identityCard', {
          fileName: identityCard.fileName,
          fileUrl: identityCard.filePath,
          file: identityCard.fileId,
        });
        this.formStateService.setUploadedFileId('identityCard', identityCard.fileId);
      }
    }
  
    if (!this.formStateService.isFileUploaded('qualificationCertificates')) {
      const qualificationCertificates = res.serviceAdvisorDocuments?.find(
        (doc: any) => doc.documentTypeId === 13
      );
      if (qualificationCertificates) {
        this.formStateService.setFormData('qualificationCertificates', {
          fileName: qualificationCertificates.fileName,
          fileUrl: qualificationCertificates.filePath,
          file: qualificationCertificates.fileId,
        });
        this.formStateService.setUploadedFileId('qualificationCertificates', qualificationCertificates.fileId);
      }
    }
  
    if (!this.formStateService.isFileUploaded('experienceCertificates')) {
      const experienceCertificates = res.serviceAdvisorDocuments?.find(
        (doc: any) => doc.documentTypeId === 14
      );
      if (experienceCertificates) {
        this.formStateService.setFormData('experienceCertificates', {
          fileName: experienceCertificates.fileName,
          fileUrl: experienceCertificates.filePath,
          file: experienceCertificates.fileId,
        });
        this.formStateService.setUploadedFileId('experienceCertificates', experienceCertificates.fileId);
      }
    }
  
    if (!this.formStateService.isFileUploaded('otherCertificates')) {
      const otherCertificates = res.serviceAdvisorDocuments?.find(
        (doc: any) => doc.documentTypeId === 15
      );
      if (otherCertificates) {
        this.formStateService.setFormData('otherCertificates', {
          fileName: otherCertificates.fileName,
          fileUrl: otherCertificates.filePath,
          file: otherCertificates.fileId,
        });
        this.formStateService.setUploadedFileId('otherCertificates', otherCertificates.fileId);
      }
    }
  
    // Patch the form with the file IDs
    this.model = {
      identityCard: this.formStateService.getUploadedFileId('identityCard') || '',
      qualificationCertificates: this.formStateService.getUploadedFileId('qualificationCertificates') || '',
      experienceCertificates: this.formStateService.getUploadedFileId('experienceCertificates') || '',
      otherCertificates: this.formStateService.getUploadedFileId('otherCertificates') || '',
    };
  
    this.form.patchValue(this.model);
    this.cdr.detectChanges(); // Ensure the UI reflects the updated data
  }
  

  clearForm(): void {
    this.formStateService.clearFormData();
  }

  onSubmit(): void {
    if (this.isLoading) return; // Prevent multiple submissions
    this.isLoading = true;
    if (this.checkServiceAdvisorId) {
      this.update();
    } else {
      this.add();
    }
  }

  update() {
    this.saveFormData();
    const data = this.formStateService.getServiceAdvisorCombinedData();
    this.api
      .updateServiceAdvisorInfo(this.checkServiceAdvisorId, data)
      .subscribe(
        (res) => {
          console.log(res);
          this.isLoading = false;
          this.handleSuccess('Service Advisor updated successfully');
        },
        () => {
          this.handleError(
            'An error occurred while updating. Please try again.'
          );
        }
      );
  }
  handleSuccess(message: string): void {
    this.isLoading = false;
    this.toastService.showToast(message, 'success');
    setTimeout(() => {
      this.updateList.emit();
      this.close.emit();
    }, 500);
  }

  handleError(message: string): void {
    this.isLoading = false;
    this.toastService.showToast(message, 'error');
  }
  add() {
    this.saveFormData();
    const data = this.formStateService.getServiceAdvisorCombinedData();
    console.log(data);
    this.isLoading = true;
      this.api.createNewServiceAdvisor(data).subscribe(
        (response) => {
          if (response.isSuccess) {
            this.toastService.showToast(
              'Service Advisor Added Successfully',
              'success'
            );
            setTimeout(() => {
              this.clearForm();
              this.isLoading = false;
              this.close.emit();
              this.updateList.emit();
            }, 1500);
            console.log('Response after post API call:', response);
          } else {
            this.handleError(response.errors);
          }
        },
        (error) => {
          this.isLoading = false;
          this.handleError(error?.error?.errors);
        }
      );
  }
}
