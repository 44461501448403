<div class="form-container">
  <div class="header-container">
    <div style="margin-bottom: 43px" class="mt-5 font-semibold text-xl">
      Additional details
    </div>
  </div>
  <form [formGroup]="form">
    <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>

    <button type="submit" class="custom-upload-button" [disabled]="isLoading" (click)="onSubmit()">
      {{ checkServiceAdvisorId && checkServiceAdvisorId != 0 ? "Update" : "Finish" }}
      <span class="arrow">➔</span>
    </button>
  </form>
  <div *ngIf="isLoading" class="overlay">
    <div class="spinner">
      <div class="spinner-bar"></div>
    </div>
  </div>
</div>